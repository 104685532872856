
import { get, patch, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEmployee, IExaGroup, IExaQAItem, IExaSku } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import DragAndDrop from "@/components/DragAndDrop.vue";
import Security from "@/components/Security.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";
import UserSelector from "@/components/UserSelector.vue";
import SingleUserSelector from "@/components/SingleUserSelect.vue";

@Component({
  components: {
    EntityPropertiesEdit,
    DragAndDrop,
    Security,
    TableRootEntities,
    UserSelector,
    SingleUserSelector,
  },
})
export default class ExaminationView extends Vue {
  headers: Array<any> = [
    {
      text: "Текст ответа",
      sortable: false,
      align: "start",
      value: "text",
    },
    {
      text: "Весы",
      sortable: false,
      align: "start",
      value: "weight",
    },
  ];

  navi_breadcrumbs: Array<any> = [
    {
      text: "Главная",
      exact: true,
      to: {
        name: "today",
        params: {},
      },
    },
    {
      text: "Библиотека вопросов",
      exact: true,
      to: {
        name: "exa_question_list_view",
        params: {},
      },
    },
    {
      text: "Редактирование вопроса",
      link: false,
    },
  ];

  fields: Array<any> = [
    {
      icon: "mdi-file-document",
      caption: "Текст вопроса",
      placeholder: "Вопрос не указано",
      name: "question",
      type: "string",
      hint: "hint",
      message: "Введите вопрос",
    },
    /*{
            "icon": "mdi-file-document",
            "caption": "Описание",
            "placeholder": "Описание",
            "name": "theme",
            "type": "string",
            "hint": "hint",
            "message": "Введите наименование темы"
        }*/
  ];

  addEmptyAnswer() {
    if (!this.isEditMode) {
      console.error("YOU ARE NOT IN EDIT MODE, ADD NEW ANSWER DECLINED");
      return;
    }
    if (!this.entity) return;
    this.entity!.answers.push({
      text: "...",
      weight: 0,
    });
  }

  public get questionID(): string {
    return this.$route.params.id;
  }

  public entity: IExaQAItem | null = null;
  public isEditMode: boolean = false;

  is_loaded: boolean = false;

  created() {
    if (this.$route.query.editMode) {
      this.isEditMode = true;
    }

    get(`/api/@examination/exa/question/${this.questionID}`).then((x) => {
      this.entity = x.data;
      console.log("Success fetch entity", x.data);
    });
  }

  saveEntity() {
    if (!this.isEditMode) {
      console.error("YOU ARE NOT IN EDIT MODE, SAVE DECLINED");
      return;
    }
    patch(`/api/@examination/exa/question/${this.questionID}`, this.entity).then((x) => {
      console.log("Success update entity", x.data);
      this.entity = x.data;
    });
  }
}
